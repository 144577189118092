<script lang="ts" setup>
import { computed, reactive, ref, watch, watchEffect } from 'vue';
import moment, { invalid } from 'moment';
import { useRoute, useRouter } from 'vue-router';
import { useLocalBranch } from '@/store/local-branch';
import { useInbound } from '@/store/inbound';
import { BASE_URL } from 'types/config';
import { Roles } from 'types/roles';

import { IAvailabilityItem, IUnavailabilityItem, IInbound, IAvailability, IUnavailability, ISlot } from 'types/inbound';
import { ILocalBranch } from 'types/local-branch';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';

import { Weekday } from 'types/weekday';

import BaseInputTime from '@/components/BaseInputTime/BaseInputTime.vue';
import BaseInputDate from '@/components/BaseInputDate/BaseInputDate.vue';
import PageHeading from '@/components/PageHeading/PageHeading.vue';

import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputTextarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';

import { useAuth } from '@/store/auth';

const route = useRoute();
const router = useRouter();
const { addToast } = useMessages();

const localBranchStore = useLocalBranch();
const inboundStore = useInbound();

await localBranchStore.getAll();

const listLocalBranch = computed(() => localBranchStore.listAll);
const inboundDropdown = computed(() =>
  listLocalBranch.value.map((item: ILocalBranch) => ({ name: item.name, value: item.id })),
);

const pageName = route.name;
const inboundId: string | string[] = route?.params?.id;

const authStore = useAuth();
const userRole = computed(() => authStore.user.role);

const children = ref<IInbound[]>([]);
const searchableChildren = ref<IInbound[]>([]);

const weekFullDay: string[] = [
  Weekday.MONDAY,
  Weekday.TUESDAY,
  Weekday.WEDNESDAY,
  Weekday.THURSDAY,
  Weekday.FRIDAY,
  Weekday.SATURDAY,
  Weekday.SUNDAY,
];

interface IFormDataNormal {
  name: string;
  type: string;
  localBranch: string;
  active: boolean;
  slots: number;
  leadTime: number;
  formConfig: string;
  email?: string;
  // in formdata these values are hours
  reminderThreshold?: number;
  reminderThresholdUnit?: 'Tag' | 'Stunden';
  assignmentWarningThreshold?: number;
  assignmentWarningThresholdUnit?: 'Tag' | 'Stunden';
  topics?: Array<string>;
  children?: Array<IInbound>;
  skipLeadTime?: boolean;
}

const defaultFormConfig = {
  firstName: {
    type: 'text',
    label: 'Vorname',
    required: true,
  },
  lastName: {
    type: 'text',
    label: 'Nachname',
    required: true,
  },
  email: {
    type: 'email',
    label: 'E-Mail',
    required: true,
  },
  phone: {
    type: 'text',
    label: 'Telefonnummer',
    required: true,
  },
  message: {
    type: 'textarea',
    label: 'Nachricht',
  },
};

const formData = ref<IFormDataNormal>({
  name: '',
  type: 'REMOTE',
  localBranch: '',
  active: false,
  slots: 1,
  leadTime: 0,
  formConfig: JSON.stringify(defaultFormConfig),
  topics: ['Kontakt', 'Sonstiges'],
  email: '',
  reminderThreshold: 0,
  reminderThresholdUnit: 'Stunden',
  assignmentWarningThreshold: 0,
  assignmentWarningThresholdUnit: 'Stunden',
  children: [],
  skipLeadTime: false,
  dataPrivacyText: '',
});

const dropdownList = ref([
  { name: 'Videoberatung', value: 'REMOTE' },
  { name: 'Vor-Ort Termin', value: 'LOCAL' },
  { name: 'Rückruf', value: 'PHONE' },
  { name: 'Kalender-Gruppe', value: 'GROUP' },
]);

const defaultSlot = () => {
  return {
    start: '',
    end: '',
    length: '',
  };
};

const availabilitiesTimes = reactive<IAvailabilityItem[]>([
  {
    label: 'Montag',
    weekday: Weekday.MONDAY,
    active: false,
    slots: [defaultSlot()],
  },
  {
    label: 'Dienstag',
    weekday: Weekday.TUESDAY,
    active: false,
    slots: [defaultSlot()],
  },
  {
    label: 'Mittwoch',
    weekday: Weekday.WEDNESDAY,
    active: false,
    slots: [defaultSlot()],
  },
  {
    label: 'Donnerstag',
    weekday: Weekday.THURSDAY,
    active: false,
    slots: [defaultSlot()],
  },
  {
    label: 'Freitag',
    weekday: Weekday.FRIDAY,
    active: false,
    slots: [defaultSlot()],
  },
  {
    label: 'Samstag',
    weekday: Weekday.SATURDAY,
    active: false,
    slots: [defaultSlot()],
  },
  {
    label: 'Sonntag',
    weekday: Weekday.SUNDAY,
    active: false,
    slots: [defaultSlot()],
  },
]);

const unAvaiableTimes = ref<IUnavailabilityItem[]>([
  {
    dateFrom: {
      date: '',
      hour: '',
    },
    dateTo: {
      date: '',
      hour: '',
    },
  },
]);

/**
 * * @returns {Number} minutes
 * @description Convert hour to minues number
 * Ex: 01:30 => 90 (minutes)
 */
const convertHourToMinutesNumber = (time: string): number => {
  return moment.duration(time).asMinutes();
};

/**
 * * @returns {String} hours
 * @description Convert minutes to hours string
 * Input: 90 //(minutes)
 * Output: 01:30 //(hours)
 */
const convertMinutesToHourString = (mins: number): string => {
  if (!mins) return '';
  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.');
  }
  const h = (mins / 60) | 0,
    m = mins % 60 | 0;
  return moment.utc().hours(h).minutes(m).format('HH:mm');
};

/**
 * * @returns {Number} timestamp
 * @description Convert datetime normal to timestamp. Just like new Date().getTime()
 * Input: '22.02.2022', '10:00'
 * Output: 1645526045569
 *
 */
const convertDateTimeToTimeStamp = (date: string, time: string): number => {
  if (!date && !time) return 0;
  return moment(`${date} ${time}'`, 'DD.MM.YY HH:mm').valueOf() / 1000;
};

/**
 * * @returns {String} timestamp
 * @description Convert timestamp to datetime with format.
 * Input: 1645526045569
 * Output: '22.02.2022'
 *
 */
const converTimeStampToDate = (timeStamp: number, format = 'DD.MM.YY'): string => {
  if (!timeStamp) return '';
  return moment(timeStamp * 1000).format(format);
};

const submitMode = ref('Erstellen');
/**
 * @description This components using for both Add/Edit routes. This code apply for when we have inboundId get on the URL
 *
 */
if (inboundId) {
  submitMode.value = 'Speichern';
  const inbound: IInbound = await inboundStore.getDetail(String(inboundId));

  formData.value = {
    name: inbound.name,
    type: inbound.type,
    localBranch: inbound?.localBranch?.id || '',
    active: inbound.active,
    slots: inbound.slots,
    leadTime: inbound.leadTime,
    formConfig: JSON.stringify(inbound.formConfig || defaultFormConfig),
    topics: inbound.topics,
    email: inbound.email,
    dataPrivacyText: inbound.dataPrivacyText,
    reminderThreshold: inbound.reminderThreshold ? inbound.reminderThreshold / (60 * 60) : 0, // get hours
    reminderThresholdUnit: formData.value.reminderThresholdUnit,
    assignmentWarningThreshold: inbound.assignmentWarningThreshold ? inbound.assignmentWarningThreshold / (60 * 60) : 0, // get hours
    assignmentWarningThresholdUnit: formData.value.assignmentWarningThresholdUnit,
    // todo fix type error
    children: inbound.children,
    skipLeadTime: inbound.skipLeadTime,
  };

  // Convert data from response API to match data components
  if (inbound.availabilityConfig?.availabilities?.length > 0) {
    availabilitiesTimes.forEach((avaibility: IAvailabilityItem) => {
      const results: IAvailability[] = [];
      inbound.availabilityConfig?.availabilities.forEach((item: IAvailability) => {
        if (item.weekday === avaibility.weekday) {
          results.push({
            start: item.start,
            end: item.end,
            length: convertMinutesToHourString(Number(item.length)),
          });
          avaibility.active = item.active || false;
        }
      });
      if (results.length == 0) {
        avaibility.slots = [defaultSlot()];
      } else {
        avaibility.slots = results;
      }
    });
  }

  // Convert data from response API to match data components
  if (inbound.availabilityConfig.unavailabilities?.length > 0) {
    const results: IUnavailabilityItem[] = [];
    inbound.availabilityConfig.unavailabilities.forEach((item: IUnavailability) => {
      const unAvaibility: IUnavailabilityItem = {
        dateFrom: {
          date: converTimeStampToDate(item.dateFrom, 'DD.MM.YY'),
          hour: converTimeStampToDate(item.dateFrom, 'HH:mm'),
        },
        dateTo: {
          date: converTimeStampToDate(item.dateTo, 'DD.MM.YY'),
          hour: converTimeStampToDate(item.dateTo, 'HH:mm'),
        },
      };
      results.push(unAvaibility);
    });
    unAvaiableTimes.value = results;
  }
}

/**
 * @description Push new item time slots of Avaibilities times
 */
const onClickAddTimeSlot = (dayIndex: number) => {
  availabilitiesTimes[dayIndex]?.slots.push({ start: '', end: '', length: '' });
};

/**
 * @description Remove item time slots of Avaibilities times
 */
const onClickRemoveTimeSlot = (dayIndex: number, timeIndex: number) => {
  return availabilitiesTimes[dayIndex]?.slots?.splice(timeIndex, 1);
};

/**
 * @description Push new item time slots of UnAvaibilities times
 */
const addUnAvaiableTimes = () => {
  unAvaiableTimes.value.push({
    dateFrom: {
      date: '',
      hour: '',
    },
    dateTo: {
      date: '',
      hour: '',
    },
  });
};

/**
 * @description Remove item time slots of UnAvaibilities times
 */
const removeUnAvaiableTimes = (dayIndex: number) => {
  return unAvaiableTimes.value?.splice(dayIndex, 1);
};

/**
 * * @returns {Boolean}
 * @description Validation for availability time item.
 * If length time is bigger than start time to end time
 *
 * For examples:
 * We have:
 * - Start time: 10:00
 * - End time: 12:00
 *
 * => Total time => 02:00 (120 minutes)
 * => Length time must have smaller or equal 120 minutes
 */
const invalidTimeLength = (start: string, end: string, length: string): boolean => {
  const _start = convertHourToMinutesNumber(start);
  const _end = convertHourToMinutesNumber(end);
  const _length = convertHourToMinutesNumber(length);
  return _start && _end ? _length > _end - _start : false;
};

/**
 * * @returns {Boolean}
 * @description This fnc is check time on input is valid with format
 */
const invalidTimeFormat = (time: string, format: string): boolean => {
  return time ? !moment(time, format, true).isValid() : false;
};

/**
 * * @returns {avaiabilitiesTimePayload}
 * @description This value using computed to calculator payload to add/edit
 */
const avaiabilitiesTimePayload = computed(() => {
  const results: IAvailability[] = [];
  availabilitiesTimes.forEach((time: IAvailabilityItem) => {
    weekFullDay.forEach((day: string) => {
      if (String(time.weekday) === String(day)) {
        time.slots.forEach((slot: ISlot) => {
          const availableItem: IAvailability = {
            weekday: time.weekday,
            start: slot.start || '',
            end: slot.end || '',
            active: time.active,
            length: convertHourToMinutesNumber(String(slot.length)) || 0,
          };
          results.push(availableItem);
        });
      }
    });
  });
  return results;
});

/**
 * @description This fnc handle add or edit inbound channel depend on inboundId get on the URL
 */
const handleSubmit = async () => {
  const payload: IInbound = {
    name: formData.value.name,
    type: formData.value.type,
    active: formData.value.active,
    slots: formData.value.slots,
    leadTime: formData.value.leadTime,
    topics: formData.value.topics,
    children: formData.value.children?.map((item: string) => item.id),
    formConfig: JSON.parse(formData.value.formConfig),
    email: formData.value.email,
    dataPrivacyText: formData.value.dataPrivacyText,
    // hours to seconds
    reminderThreshold: formData.value.reminderThreshold ? formData.value.reminderThreshold * 60 * 60 : 0,
    assignmentWarningThreshold: formData.value.assignmentWarningThreshold
      ? formData.value.assignmentWarningThreshold * 60 * 60
      : 0,

    availabilityConfig: {
      availabilities: avaiabilitiesTimePayload.value,
      unavailabilities: unAvaiableTimes.value.map((item: IUnavailabilityItem) => ({
        dateFrom: convertDateTimeToTimeStamp(item.dateFrom.date, item.dateFrom.hour),
        dateTo: convertDateTimeToTimeStamp(item.dateTo.date, item.dateTo.hour),
      })),
    },
    skipLeadTime: formData.value.skipLeadTime,
  };
  if (formData.value.type === 'LOCAL') {
    payload.localBranch = formData.value.localBranch;
  }
  if (inboundId) {
    const isSuccess: boolean = await inboundStore.update(String(inboundId), payload);
    if (isSuccess) {
      addToast({
        type: MessageType.Success,
        content: 'Kalender ändern erfolgreich',
      });
      router.push('/admin/inbound');
    } else {
      addToast({
        type: MessageType.Error,
        content: 'Kalender ändern fehlgeschlagen',
      });
    }
  } else {
    const isSuccess: boolean = await inboundStore.create(payload);
    if (isSuccess) {
      addToast({
        type: MessageType.Success,
        content: 'Kalender erstellen erfolgreich',
      });
      router.push('/admin/inbound');
    } else {
      addToast({
        type: MessageType.Error,
        content: 'Kalender erstellen fehlgeschlagen',
      });
    }
  }
};

/**
 * @description Watch effect to handle to show dropdown choosing Local Brach
 * If we're chosing Filialen and chosing Local Branch before, then we're chosing Virtuell after.
 *  We don't need param localBranch to request API.
 */
watch(
  formData,
  (value: IFormDataNormal) => {
    if (value.type === 'REMOTE') {
      formData.value.localBranch = '';
    }
  },
  { deep: true },
);
// Snippet for homepages
/* eslint-disable no-useless-escape */
const snippet = computed(
  () => `<script>
var head  = document.getElementsByTagName("head")[0];
var link  = document.createElement("link");
link.rel  = "stylesheet";
link.type = "text/css";
link.href = "${BASE_URL}/uploads/scripts/style.css";
head.appendChild(link);
var appointmentTool  = document.createElement("script");
appointmentTool.type = "text/javascript";
appointmentTool.src = "${BASE_URL}/uploads/scripts/netconnex-appointment-user-form.umd.js";
head.appendChild(appointmentTool);
<\/script>
<div id="netconnex-appointment-user-form" data-inbound-id="${inboundId}" data-fullscreen="false"></div>`,
);

const iframeUrl = computed(() => `${BASE_URL}/inbound/snippet/${inboundId}`);
const iframe = `
<iframe src="${iframeUrl.value}" style="width: 100%; height: 100vh; border: none;"></iframe>`;

const copyIcon = reactive({
  snippet: 'pi-copy',
  iframe: 'pi-copy',
});

// Copy to clipboard function
function copyToClipboard(copy: string, scopedIcon: keyof typeof copyIcon) {
  navigator.clipboard.writeText(copy).then(() => {
    copyIcon[scopedIcon] = 'pi-check';
    setTimeout(() => {
      copyIcon[scopedIcon] = 'pi-copy';
    }, 1000);
  });
}

const isDisabledBtn = ref(true);
watchEffect(() => {
  isDisabledBtn.value =
    availabilitiesTimes.some(
      (t: {
        label: string;
        weekday: string;
        active: boolean;
        slots: {
          start: string;
          end: string;
          length: string | number;
        }[];
      }) => {
        if (t.active) {
          for (const slot of t.slots) {
            if (
              !slot.start ||
              !slot.end ||
              !slot.length ||
              invalidTimeFormat(slot.start, 'HH:mm') ||
              invalidTimeFormat(slot.end, 'HH:mm') ||
              invalidTimeFormat(slot.length as string, 'HH:mm') ||
              invalidTimeLength(slot.start, slot.end, slot.length as string)
            ) {
              return true;
            }
          }
        }
        return false;
      },
    ) ||
    unAvaiableTimes.value.some(
      (t: {
        dateFrom: {
          date: string;
          hour: string;
        };
        dateTo: {
          date: string;
          hour: string;
        };
      }) => {
        return (
          // any of the items is not set
          (!t.dateFrom.date ||
            !t.dateFrom.hour ||
            !t.dateTo.date ||
            !t.dateTo.hour ||
            invalidTimeFormat(t.dateFrom.date, 'DD.MM.YY') ||
            invalidTimeFormat(t.dateFrom.hour, 'HH:mm') ||
            invalidTimeFormat(t.dateTo.date, 'DD.MM.YY') ||
            invalidTimeFormat(t.dateTo.hour, 'HH:mm')) &&
          // not all of the items are not set
          !(!t.dateFrom.date && !t.dateFrom.hour && !t.dateTo.date && !t.dateTo.hour)
        );
      },
    );
});
// chihldren

watchEffect(async () => {
  if (formData.value.type === 'GROUP') {
    children.value = (await inboundStore.getAll()).filter((item: IInbound) => item.type !== 'GROUP');
    searchableChildren.value = [...children.value];
  }
});
function autoCompleteSearch(event) {
  if (!event.query.trim().length) {
    searchableChildren.value = [...children.value];
  } else {
    searchableChildren.value = children.value.filter((child) => {
      return child.name.toLowerCase().startsWith(event.query.toLowerCase());
    });
  }
}
function checkForDuplicates(event: { value: Array<string> }) {
  formData.value.topics = formData.value.topics?.filter(onlyUnique);
}
function onlyUnique(value, index, array) {
  if (array.indexOf(value) === index) return value;
  addToast({
    type: MessageType.Error,
    content: 'Thema bereits hinzugefügt',
  });
}
</script>

<template>
  <div class="page-content w-full branch-add pb-14">
    <PageHeading class="mb-5" :title="String(pageName)" />
    <div class="container-wrapper">
      <div class="grid grid-cols-1 px-4 md:px-8 mt-14 md:mt-6">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Name</div>
          <div class="mb-6">Wählen Sie den Namen des Kalenders</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <InputText
              v-model="formData.name"
              placeholder="Name des Kanals"
              class="w-full md:w-48 h-9 text-s placeholder:text-light-grey p-2 rounded"
            />
          </div>
        </div>
        <div v-if="inboundId" class="field mt-6 md:mt-12">
          <div class="text-l mb-4 font-semibold">Typ</div>
          <div>{{ dropdownList.find((item) => item.value === formData.type)?.name }}</div>
        </div>
        <div class="field mt-6 md:mt-12">
          <template v-if="!inboundId">
            <div class="text-l text-black mb-4 font-semibold">Art</div>
            <div class="mb-6">Um welche Art von Kalender handelt es sich?</div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
              <Dropdown
                v-model="formData.type"
                :options="dropdownList"
                class="w-full md:w-48 h-9 text-s placeholder:text-light-grey rounded"
                option-label="name"
                option-value="value"
                placeholder="Kalender Typ wählen"
              />
            </div>
          </template>
          <div v-if="formData.type === 'LOCAL'" class="mt-6">
            <div class="mb-3">Wählen Sie die Filiale aus, zu der der Kalender gehört.</div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
              <Dropdown
                v-model="formData.localBranch"
                :options="inboundDropdown"
                class="w-full md:w-48 h-9 text-s placeholder:text-light-grey rounded"
                option-label="name"
                option-value="value"
                placeholder="Filliale wählen"
              />
            </div>
          </div>
          <div v-if="formData.type === 'GROUP'" class="mt-6">
            <div class="mb-3">Wählen Sie die Themen Kalender aus.</div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
              <div>
                <AutoComplete
                  v-model="formData.children"
                  :suggestions="searchableChildren"
                  option-label="name"
                  multiple
                  placeholder="Themen Kalender"
                  class="text-s placeholder:text-light-grey rounded"
                  @complete="autoCompleteSearch"
                />
              </div>
            </div>
          </div>
        </div>
        <template v-if="formData.type !== 'GROUP'">
          <div class="field mt-6 md:mt-12">
            <div class="text-l text-black mb-4 font-semibold">Verfügbarkeit</div>
            <div class="mb-6">Termine können gebucht werden an den folgenden Wochentagen:</div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 w-full md:w-10/12">
              <div
                v-for="(day, dayIndex) in availabilitiesTimes"
                :key="dayIndex"
                :class="{ disabled: !day.active }"
                class="grid gap-x-4 gap-y-6 grid-cols-12 time-item"
              >
                <div class="flex items-center col-span-3 self-start">
                  <InputSwitch v-model="day.active" :class="!day.active ? 'disabled' : ''" />
                  <span class="ml-4 text-dark-grey">{{ day.label }}</span>
                </div>
                <TransitionGroup name="list" tag="div" class="col-start-4 col-span-8">
                  <div
                    v-for="(time, timeIndex) in day.slots"
                    :key="timeIndex"
                    class="grid grid-cols-12 grid-rows-1 mt-6 first:mt-0"
                  >
                    <div class="col-span-6">
                      <span class="mr-4 text-dark-grey">von</span>
                      <BaseInputTime
                        class="w-full md:w-20 h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                        placeholder="hh:mm"
                        :value="time.start"
                        :invalid="invalidTimeFormat(time.start, 'HH:mm')"
                        @input="time.start = $event"
                      />

                      <span class="mx-4 text-dark-grey">bis</span>
                      <BaseInputTime
                        class="w-full md:w-20 h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                        placeholder="hh:mm"
                        :value="time.end"
                        :invalid="invalidTimeFormat(time.end, 'HH:mm')"
                        @input="time.end = $event"
                      />
                    </div>
                    <div class="col-span-4">
                      <span class="mr-4 text-dark-grey">Terminlänge</span>
                      <BaseInputTime
                        class="w-full md:w-20 h-9 text-s placeholder:text-light-grey p-2"
                        placeholder="hh:mm"
                        :value="String(time.length)"
                        :invalid="
                          invalidTimeLength(time.start, time.end, String(time.length)) ||
                          invalidTimeFormat(time.end, 'HH:mm')
                        "
                        @input="time.length = $event"
                      />
                    </div>
                    <div>
                      <i
                        v-if="day.slots.length > 1"
                        class="pi pi-trash text-m cursor-pointer text-red hover:text-red p-2 !leading-4"
                        @click="onClickRemoveTimeSlot(dayIndex, timeIndex)"
                      ></i>
                    </div>
                    <div>
                      <template v-if="day.active">
                        <a
                          v-if="day.slots.length === timeIndex + 1"
                          class="cursor-pointer inline-block bg-first rounded flex items-center justify-center border-none hover:bg-first !py-2 !w-8"
                          @click="onClickAddTimeSlot(dayIndex)"
                        >
                          <i class="text-white pi pi-plus"></i>
                        </a>
                      </template>
                      <template v-else>
                        <i class="!py-2 !w-8 invisible"></i>
                      </template>
                    </div>
                  </div>
                </TransitionGroup>
              </div>
            </div>
          </div>
          <div class="field mt-6 md:mt-12">
            <div class="text-l text-black mb-4 font-semibold">Terminanzahl</div>
            <div class="mb-6">Wie viele Termine können pro Slot gleichzeitig gebucht werden?</div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
              <InputNumber
                v-model="formData.slots"
                :use-grouping="false"
                placeholder="Anzahl"
                mode="decimal"
                class="w-full md:w-48 !h-9 text-s placeholder:text-light-grey p-2 rounded"
              />
            </div>
          </div>
          <div class="field mt-6 md:mt-12">
            <div class="text-l text-black mb-4 font-semibold">Abwesenheit</div>
            <div class="mb-6">Terminbuchung ist nicht möglich in diesen Zeiträumen:</div>
            <TransitionGroup name="list" tag="div" class="w-full md:w-10/12">
              <div
                v-for="(day, dayIndex) in unAvaiableTimes"
                :key="dayIndex"
                class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-1 time-item mt-6 first:mt-0"
              >
                <div class="flex items-center">
                  <div class="flex items-center">
                    <span class="mr-4 text-dark-grey">vom</span>
                    <BaseInputDate
                      class="w-full md:w-20 h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                      placeholder="dd.mm.jj"
                      :value="day.dateFrom.date"
                      :invalid="invalidTimeFormat(day.dateFrom.date, 'DD.MM.YY')"
                      @input="day.dateFrom.date = $event"
                    />
                    <span class="mx-4 text-dark-grey">ab</span>
                    <BaseInputTime
                      class="w-full md:w-20 h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                      placeholder="hh:mm"
                      :value="day.dateFrom.hour"
                      :invalid="invalidTimeFormat(day.dateFrom.hour, 'HH:mm')"
                      @input="day.dateFrom.hour = $event"
                    />
                  </div>
                  <div class="flex items-center ml-12">
                    <span class="mr-4 text-dark-grey">bis zum</span>
                    <BaseInputDate
                      class="w-full md:w-20 h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                      placeholder="dd.mm.jj"
                      :invalid="invalidTimeFormat(day.dateTo.date, 'DD.MM.YY')"
                      :value="day.dateTo.date"
                      @input="day.dateTo.date = $event"
                    />
                    <span class="mx-4 text-dark-grey">um</span>
                    <BaseInputTime
                      class="w-full md:w-20 h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                      placeholder="hh:mm"
                      :invalid="invalidTimeFormat(day.dateFrom.hour, 'HH:mm')"
                      :value="day.dateTo.hour"
                      @input="day.dateTo.hour = $event"
                    />
                  </div>
                  <div class="flex items-center ml-12">
                    <i
                      v-if="unAvaiableTimes.length > 1"
                      class="pi pi-trash text-m cursor-pointer text-red hover:text-red p-2 !leading-4"
                      @click="removeUnAvaiableTimes(dayIndex)"
                    ></i>
                  </div>
                  <div class="flex items-center ml-12">
                    <Button
                      v-if="unAvaiableTimes.length === dayIndex + 1"
                      class="cursor-pointer inline-block bg-first rounded flex items-center justify-center border-none hover:bg-first !py-2 !w-8"
                      @click="addUnAvaiableTimes()"
                    >
                      <i class="text-white pi pi-plus"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </TransitionGroup>
          </div>

          <div class="field mt-6 md:mt-12">
            <div class="text-l text-black mb-4 font-semibold">Vorlaufzeit</div>
            <div class="mb-6">Wie viele Minuten im Voraus dürfen Termine von Kunden gebucht werden?</div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
              <InputNumber
                v-model="formData.leadTime"
                :use-grouping="false"
                placeholder="Vorlaufzeit"
                mode="decimal"
                class="w-full md:w-48 !h-9 text-s placeholder:text-light-grey p-2 rounded"
              />
            </div>
          </div>
          <div class="field mt-6 md:mt-12">
            <div class="text-l text-black mb-4 font-semibold">Thema</div>
            <div class="mb-6">Welche Themen werden von diesem Kalender behandelt?</div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
              <Chips
                v-model="formData.topics"
                placeholder="'Thema' + Enter"
                class="w-full text-s placeholder:text-light-grey rounded"
                @add="checkForDuplicates"
              />
            </div>
          </div>
          <div v-if="Roles[userRole] === Roles.superAdmin" class="field mt-6 md:mt-12">
            <div class="text-l text-black mb-4 font-semibold">Formular Felder</div>
            <div>
              Mögliche Konfigurationen: <br />
              <span class="text-xs">
                {"firstName":{"type":"text","label":"Vorname","required":true},<br />
                "lastName":{"type":"text","label":"Nachname","required":true},<br />
                "email":{"type":"email","label":"E-Mail","required":true},<br />
                "message":{"type":"textarea","label":"Nachricht","required":true},<br />
                "birthday":{"type":"date","label":"Geburtsdatum","required":true}}</span
              >
            </div>
            <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2 mt-2">
              <InputTextarea
                v-model="formData.formConfig"
                placeholder="Formular Felder"
                :auto-resize="true"
                class="w-full text-s placeholder:text-light-grey p-2 rounded"
              />
            </div>
          </div>
          <div class="field mt-6 md:mt-12">
            <div class="text-l text-black mb-4 font-semibold">Vorlaufzeit Überprüfung beachtet Servicezeiten nicht</div>
            <div class="mt-4 flex gap-2">
              <Checkbox id="skipLeadTime" v-model="formData.skipLeadTime" />
              <label for="skipLeadTime">Vorlaufzeit ignorieren</label>
            </div>
          </div>
          <div class="field mt-6 md:mt-12">
            <div class="text-l text-black mb-4 font-semibold">Datenschutz Text</div>
            <div>
              <Editor
                v-model="formData.dataPrivacyText"
                class="w-full text-s placeholder:text-light-grey p-2"
                editor-style="height: 320px"
              ></Editor>
            </div>
          </div>
          <div class="field mt-6 md:mt-12 grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <div class="text-l text-black mb-4 font-semibold">Benachrichtigungen</div>
            <div class="col-start-1">
              Tragen Sie hier die E-Mail-Adressen ein, an die Benachrichtigungen gesendet werden sollen. <br />
              Zusätzlich können noch Zeiten für die Benachrichtigungen eingestellt werden.
            </div>
            <div class="mt-2 flex items-center col-start-1 gap-4 grid grid-cols-2">
              <label for="notificationEmail" class="text-dark-grey"
                >E-Mail-Adresse für interne Benachrichtigungen <br />Kann eine Kommaseparierte Liste (,) sein</label
              >
              <InputText
                id="notificationEmail"
                v-model="formData.email"
                class="w-full h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
              />
              <label for="reminderThreshold" class="text-dark-grey">Kunden Erinnerung</label>
              <div class="flex gap-4">
                <InputText
                  id="reminderThreshold"
                  v-model="formData.reminderThreshold"
                  type="number"
                  class="w-full h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                />
                <div class="flex items-center">Stunden</div>
              </div>
              <label for="assignmentWarningThreshold" class="text-dark-grey"
                >Warnung für nicht berarbeitete Anfragen</label
              >
              <div class="flex gap-4">
                <InputText
                  id="assignmentWarningThreshold"
                  v-model="formData.assignmentWarningThreshold"
                  type="number"
                  class="w-full h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                />
                <div class="flex items-center">Stunden</div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="inboundId" class="field mt-6 md:mt-12">
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <Panel header="Snippet">
              <template #icons>
                <Button
                  title="Copy to clipboard"
                  :icon="'pi ' + copyIcon.snippet"
                  class="p-button-text"
                  @click="copyToClipboard(snippet, 'snippet')"
                />
              </template>
              <pre class="overflow-scroll"><code>{{ snippet }}</code></pre>
            </Panel>
            <Panel header="Iframe">
              <template #icons>
                <Button
                  title="Copy to clipboard"
                  :icon="'pi ' + copyIcon.iframe"
                  class="p-button-text"
                  @click="copyToClipboard(iframe, 'iframe')"
                />
              </template>
              <pre class="overflow-scroll"><code>{{ iframe }}</code></pre>
            </Panel>
          </div>
        </div>
        <div v-if="inboundId" class="field mt-6 md:mt-12">
          <div class="grid gap-x-4 gap-y-6">
            <Panel header="Preview">
              <iframe :src="iframeUrl" style="width: 100%; height: 100vh; border: none"></iframe>
            </Panel>
          </div>
        </div>
        <div class="mt-6 md:mt-12 flex items-center">
          <Button
            label="Abbrechen"
            class="p-button-outlined p-button-secondary mr-2 text-s text-black"
            @click="router.push('/admin/inbound')"
          />
          <Button
            :disabled="isDisabledBtn"
            :label="submitMode"
            class="p-button-primary bg-first hover:!bg-first border-none text-s"
            @click="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.time-item.disabled span {
  @apply text-light-grey;
}
.time-item.disabled input {
  @apply pointer-events-none;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.branch-add .p-dropdown .p-inputtext {
  @apply !p-2 text-s;
}
.branch-add .p-inputnumber .p-inputnumber-input {
  @apply !p-2 text-s h-9;
}
.branch-add .p-dropdown.p-focus {
  @apply !shadow-xs !border-first;
}
</style>
